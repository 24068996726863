.App {
    animation: fade-in .7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    width: 100%;
   
}



* {
    -webkit-tap-highlight-color: transparent;
    
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    cursor: pointer;
}

a:visited {
    text-decoration: none;
}

*::-webkit-scrollbar {
    display: none;
    }
  @keyframes fade-in {
    0% {
      opacity: 0;
    } 50% {
        opacity: .2;
    }
    100% {
      opacity: 1;
    }
  }


@font-face {
    font-family: "Sanz-Reg";
    src: local("Sanzettica-RegCond"), 
    url(/static/media/Sanzettica-RegCond.a7079513.ttf) format("truetype");
   }
  
   @font-face {
    font-family: "Sanz-Thin";
    src: local("Sanzettica-ThinCond"), 
    url(/static/media/Sanzettica-ThinCond.2ca5a4fa.ttf) format("truetype");
   }

   @font-face {
    font-family: "Sanz-Thin-Reg";
    src: local("Sanzettica-Thin"), 
    url(/static/media/Sanzettica-Thin.888509e4.ttf) format("truetype");
   }

   @font-face {
    font-family: "Sanz-Thin-Expd";
    src: local("Sanzettica-ThinExpd"), 
    url(/static/media/Sanzettica-ThinExpd.862d9c10.ttf) format("truetype");
   }
  
   @font-face {
    font-family: "Sanz-Reg-Expd";
    src: local("Sanzettica-RegExpd"), 
    url(/static/media/Sanzettica-RegExpd.58b303d5.ttf) format("truetype");
   }
  

   @font-face {
    font-family: "Sanz-Thin-Italic";
    src: local("Sanzettica-ThinItalic"), 
    url(/static/media/Sanzettica-ThinItalic.6e407c72.ttf) format("truetype");
   }

   @font-face {
    font-family: "Sanz-Bold";
    src: local("Sanzettica-Bold"), 
    url(/static/media/Sanzettica-BoldCond.f003ec20.ttf) format("truetype");
   }

   @font-face {
    font-family: "Sanz-Heavy";
    src: local("Sanzettica-Heavy"), 
    url(/static/media/Sanzettica-HeavyCond.fde78c90.ttf) format("truetype");
   }

   @font-face {
    font-family: "Sanz-Black";
    src: local("Sanzettica-Black"), 
    url(/static/media/Sanzettica-BlackCond.67d3c245.ttf) format("truetype");
   }
  

@font-face {
    font-family: "Galyon-Bold";
    src: local("Galyon-Bold"), 
    url(/static/media/Galyon-Bold.0c0533b8.otf) format("opentype");
   }

   @font-face {
    font-family: "Galyon-Italic";
    src: local("Galyon-Italic"), 
    url(/static/media/Galyon-Italic.3cc539b0.otf) format("opentype");
   }
  
   @font-face {
    font-family: "Galyon-Regular";
    src: local("Galyon-Regular"), 
    url(/static/media/Galyon-Regular.df2578ea.otf) format("opentype");
   }



  
   a:hover, button:hover {
    cursor: pointer;
   }

   

   .video-container {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
    -webkit-mask-image: -webkit-radial-gradient(white, black); 

   }

   .main-body-container {
   position: absolute;
   top: 0; left: 0;

   width: 100vw;
   overflow: hidden;
    z-index: 0;

   display: block; 
   padding-top: 0px;
   text-align: center;
   padding-bottom: 40px;
   background: linear-gradient(160deg, white, white, #772dac, #772dac, #f43ea8);

   }

   video.work-sample {
    width: 140%;
    margin-left: -20%;
    filter: brightness(1.03);
    position: relative; 
    display: block;
    margin-top: -8px;
    

   }

   video.work-sample2 {
    width: 140%;
    margin-left: -20%;
    filter: brightness(1.03);
   

   }

   video.work-sample3 {
    width: 100%;
    margin-left: 0%;
    margin-top: -45%;
    filter: brightness(1.03);
 
  
   }

   .fade-in {
	animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


.title-3 {
    font-size: 16px;
    font-weight: 600;
    margin-top: -50px;
    letter-spacing: .7px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-family: "Sanz-Thin-Reg", sans-serif;
    text-transform: uppercase;
}

.title-4 {
    font-size: 14px;

    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-family: "Sanz-Reg", sans-serif;
}

.gray {
    color: rgb(183, 183, 183);
    font-style: italic;
    font-family: "Sanz-Reg", sans-serif;
    padding: 10px;
   
}
   

.link-containerbf {   position: relative;
    display: block;
    margin: 40px auto;}

    

    .link-container {
        width: 96%;
        height: 360px;
        position: relative;
        display: block;
        margin: 11px auto;
 
   
        text-align: center;

        box-shadow: rgba(109, 100, 111, 0.3) 0px 7px 29px 0px;
   
        overflow: hidden;
    }

    .link-overlay-container {

        position: absolute;
        width: 100%; height: 100%;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.954), transparent, transparent, rgba(255, 255, 255, 0.213), rgba(255, 255, 255, 0.944), white,  white);
        z-index: 99;
    
        overflow: hidden;

    }

    .link-overlay-container-black {
        font-family: "Galyon-Reg", sans-serif;
        position: absolute;
        width: 100%; height: 100%;
        background: linear-gradient(to bottom, black, transparent, transparent, transparent, transparent, transparent, rgba(0, 0, 0, 0.641), black,  black);
        z-index: 99;
        background: linear-gradient(to bottom, black,  transparent, transparent, #21062f, #271332);
        background: linear-gradient(to bottom, #271332, transparent, transparent, #21062fb5, #21062f, #271332);


    }

    .link-overlay-container-black2 {
        font-family: "Galyon-Reg", sans-serif;
        position: absolute;
        width: 100%; height: 100%;
        background: linear-gradient(to bottom, black, transparent, transparent, transparent, transparent, transparent, rgba(0, 0, 0, 0.641), black,  black);
        z-index: 99;
        background: linear-gradient(to bottom, white,  transparent, transparent, black, black);
        background: linear-gradient(to bottom, black,  transparent, transparent, transparent, rgba(0, 0, 0, 0.616), black, black);
     
    }


    picture {
        width: auto;
        height: 100%;
        margin: 0 auto;
        padding: 0;
        display: block;
        position: relative;
        overflow: hidden;

    }

    source, img {
        width: 100%;
        position: relative;
        margin: 0 auto;
       
    }

    img.work-sample2 {
        width: 140%;
        left: -19%;
        top: -45px;
      
    }

    img.work-sample3 {
        width: 100%;
        margin-top: -50%;
      
    }

    .zoomed {
        width: 120%;
        display: block;
        position: absolute;
        left: 50%; top: 50%;
        transform: translate(-50%, -50%);
  
    }

    .text-overlay-container {
        display: block;
        position: absolute;
        z-index: 999;
        bottom: 25%; transform: translate(0, 25%);
        margin: 0 auto;
        width: 94%;
        left: 20px;
    }

    .title {
        font-family: "Sanz-Thin-Reg", sans-serif;
       
        text-align: left;
        text-transform: uppercase;
        color: rgb(0, 0, 0);
        font-size: 18px;    
        font-weight: 100;
  
        line-height: 1em;
        letter-spacing: .7px;
     
      
   
    }

    .title.wht.c {
        text-align: center;
        position: absolute;
        top: -200px;
        font-family: "Sanz-Thin-Reg", sans-serif;

        color: black;
    }


    .title.wht.c.studio {
      
        text-align: center;
        position: absolute;
        top: 18px; left: 10px;
        z-index: 9999;
        font-family: "Sanz-Reg-Expd", sans-serif;
        color: black;
        font-weight: 400;
        font-size: 16px;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.243);

        letter-spacing: 1.5px;
    }

    .title.wht.c.studio.white {
      
        text-align: center;
        position: absolute;
        top: 18px; left: 10px;
        z-index: 9999;
        font-family: "Sanz-Reg-Expd", sans-serif;
        color: white;
        font-weight: 400;
        font-size: 16px;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.243);

        letter-spacing: 1.5px;
    }

    .galyon {
        font-family: "Galyon-Bold", sans-serif;
    }

    .title-2 {
        font-family: "Galyon-Regular", sans-serif;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

        font-size: .9rem;

        line-height: 1.4em;
 
        text-align: left;
        color: rgb(27, 27, 27);
        background: linear-gradient(to left, #2E2F2F, #2E2F2F, #454747, #454747, #E7EBEC, #454747, #2E2F2F, #181919);
        background: linear-gradient(to left, #151515, #202020, #101010, #272727, #ffffff, #1d1d1d, #131313, #181919);
        background-size: 300% 300%;
        background-clip: text;
        -webkit-background-clip: text;
        color: rgba(0, 0, 0, 0);
        font-weight: 500;
        letter-spacing: -0.4px;
        padding-bottom: 2px;
        animation: WelcomePageGradient 10s ease infinite;
    }

    span.moving-grad {
        text-shadow: 2px 2px 5px rgba(8, 8, 8, 0.107);
     
        font-weight: 600;
        font-size: 28px;
       
    }

 

    .moving-grad-wht {background: linear-gradient(to left, rgba(3, 3, 3, 0.26), #772dac, #772dac, rgb(0, 0, 0), #f43ea8, #f43ea89c);

     
        background: linear-gradient(to left,#772dac, #f43ea8);
    
        padding-top: 10px;
        padding-bottom: 5px; 
        letter-spacing: 0;
        background-clip: text;
        
        -webkit-background-clip: text;
        -webkit-font-smoothing: antialiased;
        color: transparent;
      
        -webkit-text-stroke-color: white;

 
    }     
    
    .box-border-top {
        line-height: 1.4em;
        margin-top: 4px;
        border-top: 1px solid rgba(41, 41, 41, 0.318);
    }

    .wht-top {
        line-height: 1.4em;
        margin-top: 4px;
        border-top: 1px solid rgba(251, 251, 251, 0.318);
    }

    .visit-button {
        position: relative;
   
        z-index: 9999;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .action-buttonbf {
        display: block;
        position: absolute;
        z-index: 900000;
        background-color: transparent;
        font-family: "Sanz-Heavy", sans-serif;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        letter-spacing: 0px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 18px;
        padding-right: 18px;
        margin: 0px auto;
        margin-top: -20px;
        vertical-align: middle;
   
        color: white;
       
        text-align: center;
        text-transform: uppercase;
        
        -webkit-appearance: none;
        transition: .4s;
        border-image: linear-gradient(to right, #005e7f, #61b6cd);
        border-radius: 50px;
        background-size: 100%;
        background: rgb(0, 0, 0);
        border: 2px transparent;
        border-radius: 50px;
        background-origin: border-box;
        background-clip: content-box, border-box;
        vertical-align: middle;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        left: 50%; transform: translate(-50%);
        padding-top: 14px;
        padding-bottom: 15px;
        font-weight: 700;
        font-size: .875rem;
       
        text-transform: uppercase;
      
        }

    .action-button {
        display: block;
        position: absolute;
        z-index: 900000;
        background-color: transparent;
        font-family: "Sanz-Heavy", sans-serif;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        letter-spacing: .7px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 18px;
        padding-right: 18px;
        margin: 0px auto;
        margin-top: -20px;
        vertical-align: middle;
   
        color: white;
       
        text-align: center;
        text-transform: uppercase;
        
        -webkit-appearance: none;
        transition: .4s;
        border-image: linear-gradient(to right, #005e7f, #61b6cd);
        border-radius: 50px;
        background-size: 100%;
        background: rgb(0, 0, 0);
        border: 2px transparent;
        border-radius: 50px;
        background-origin: border-box;
        background-clip: content-box, border-box;
        vertical-align: middle;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        
        bottom: 30px; right: 15px;
        font-weight: 600;
        font-size: .875rem;
       
        text-transform: uppercase;
      
        }

        .action-buttonbf::after {
            z-index: -100000;
            border-radius: 50px;
            content: '';
            display: block;    
            position: absolute;
            top: -2px;
            left: -1px;
            width: calc(100% + 2.5px);
            height: calc(100% + 2px);
            transform: translateZ(-1px);
            background: linear-gradient(306deg, #772dac, #f43ea8 );
            background-size: 400% 400%;
            animation: WelcomePageGradient 4s ease infinite;
            }
    
        
        .action-button::after {
        z-index: -100000;
        border-radius: 50px;
        content: '';
        display: block;    
        position: absolute;
        top: -2px;
        left: -1px;
        width: calc(100% + 2.5px);
        height: calc(100% + 2px);
        transform: translateZ(-1px);
        background: linear-gradient(306deg, #772dac, #f43ea8 );
        background-size: 400% 400%;
        animation: WelcomePageGradient 4s ease infinite;
        }

        .small-desc {
            font-family: "Sans-Thin", sans-serif;
            font-size: 10px;
            position: absolute;
            color: black;
            right: 15px;
            bottom: 12px;
            z-index: 9999;
        }

        .small-desc.l {
            font-family: "Sans-Thin", sans-serif;
            font-size: 11px;
            position: absolute;
            color: #000000;
            right: unset;
            left: 20px;
            top: 0px;
            z-index: 9999;

           
        }

        .main-title {
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        
            color: black;
            text-transform: uppercase;
            letter-spacing: .6px;
            font-size: 10.5px;
            margin-top: 0px;
            margin-bottom: 0px;
            padding-top: 20px;
       
            background: white;
            padding-bottom: 0px;
            line-height: 1.4em;
            color: rgb(27, 27, 27);
        
        }
        
        .gradient-underline {
            width: 100%;
            margin-top: 12px;
            height: 2.5px; 
            margin-bottom: 7px;
            background: linear-gradient(to left, #2E2F2F, #2E2F2F, #454747, #454747, #E7EBEC, #454747, #2E2F2F, #181919);
            background: linear-gradient(to left,transparent, #772dac, #f43ea8);
        }

        .main-title a {
            margin: 0 auto;
            font-weight: 700;
            font-size: 1.1em;
            background: linear-gradient(to left, #2E2F2F, #2E2F2F, #454747, #000000, #E7EBEC, #454747, #2E2F2F, #181919);
            background: linear-gradient(to left, #020202, #1a1a1a, #181818, #141414, #ffffff, #1a1a1a, #151515, #161616);
            font-family: "Sanz-Reg", sans-serif;
         
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            background-size: 400% 400%;
            background-clip: text;
            -webkit-background-clip: text;
            letter-spacing: -1px;
            color: rgba(0, 0, 0, 0);
            text-transform: none;
            padding: .6rem;
            padding-top: 0;
            animation: WelcomePageGradient 10s ease infinite;
 
            font-size: .875rem;
            line-height: 1.15rem;
            letter-spacing: -.4px;
        }



        .main-title-2 {
            font-family: "Sanz-Reg", sans-serif;
       
            color: white;
            font-size: 16px;

            font-weight: 300;
            letter-spacing: .2px;
            text-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            text-shadow: 2px 3px 5px rgba(0,0,0,0.2);
            padding-bottom: 20px;
            
        }

        .main-title span {

            font-size: 40px;
            position: fixed;
            left: 10px;
         
            font-weight: 500;
            letter-spacing: .6px;
            background: linear-gradient(to left,#772dac, #f43ea8);
            
        -webkit-background-clip: text;
        -webkit-font-smoothing: antialiased;
        color: transparent;
       
        }  

   .wht {
    color: white;
   }

   .title-2.wht {
    background: linear-gradient(to left, #2E2F2F, #2E2F2F, #454747, #454747, #E7EBEC, #454747, #2E2F2F, #181919);
    background: linear-gradient(to left, white, white, #f8f8f8, #ffffff, #171717, #f2f2f2, #f8f8f8, #f1f1f1, white,  white);
    background-size: 300% 300%;
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(255, 255, 255, 0.214);
    font-weight: 500;
    line-height: 1.4em;
    letter-spacing: -0.4px;
    padding-bottom: 2px;
    animation: WelcomePageGradient 10s ease infinite;
    font-size: .9rem;
   }

   .title-2.gld {
    background: linear-gradient(to left, #2E2F2F, #2E2F2F, #454747, #454747, #E7EBEC, #454747, #2E2F2F, #181919);
    background: linear-gradient(to left, white, white, #f8f8f8, #f1f1f1, #373737, #a2a2a2, #f8f8f8, #f1f1f1, white,  white);
    background: linear-gradient(to left, white, white, #f8f8f8, #ffffff, #171717, #f2f2f2, #f8f8f8, #f1f1f1, white,  white);
    
    background-size: 300% 300%;
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(255, 255, 255, 0.214);
    font-weight: 500;
    line-height: 1.4em;
    padding-bottom: 2px;
    animation: WelcomePageGradient 10s ease infinite;
    font-size: .9rem;
    letter-spacing: -0.4px;
   }


   

   .desc-blk {
    position: absolute;
    left: 20px; z-index: 99;
    color: rgba(17, 17, 17, 0.866);

 
    top: 96%; transform: translate(0, -96%);
    overflow: visible;
    font-family: "Sanz-Bold", sans-serif;
    font-size: .7rem;
    width: 62%;
    line-height: 1.4em;
    text-align: left;
   }

   .desc-wht {
    position: absolute;
    left: 20px; z-index: 99;

    overflow: visible;
    color: rgba(244, 244, 244, 0.866);
    bottom: 18px;
    position: absolute;
    top: 89%; transform: translate(0, -89%);
    font-family: "Sanz-Bold", sans-serif;
    font-size: .7rem;
    width: 65%;

    line-height: 1.4em;

    text-align: left;
   }

   .background-image {
    position: fixed;

    z-index: -1;
    top: -60px;
    left: 0;
   }
   .moving-grad {
    
    font-family: "Sanz-Reg-Expd", sans-serif;
    background: linear-gradient(to left,#772dac, #f43ea8);

 

    margin-top: 0px;
    margin-left: -1px;
    margin-right: -4px;
    letter-spacing: 0px;
    background-clip: text;
    font-weight: 400;
    -webkit-background-clip: text;
    -webkit-font-smoothing: antialiased;
    color: transparent;
    font-size: 28px;
    letter-spacing: 1px;
    vertical-align: top;
    text-shadow: none;
    text-shadow: 2px 2px 2px rgba(8, 8, 8, 0.107);
    

}     

.bold {
   font-family: "Sanz-Bold", sans-serif;
   font-family: Founders Grotesk,sans-serif;
   font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   font-size: 1.2rem;
    font-weight: 700;
   padding-bottom: 1px;
   -webkit-font-smoothing: antialiased;


  
}

source.work-sample2, img.work-sample2 {
    height: 500px;
    width: auto;
    margin-left: -13%;
    top: -82px;

}

.navbar-aam {
    width: 17%;
    top: 145px;
    right: 20px;
    position: absolute;
    z-index: 99;
}

.best-of img{
    position: absolute;
    width: 15%;
    right: 10px;
    top: 10px;
    z-index: 99
}

span.moving-grad2 {
    
    font-family: "Sanz-Reg-Expd", sans-serif;
    background: linear-gradient(to left,#772dac, #f43ea8);

 

    margin-left: -8px;
    letter-spacing: 0px;
    -webkit-background-clip: text;
            background-clip: text;
    font-weight: 700;
    -webkit-backgrund-clip: text;
    position: relative;
    color: transparent;
    font-size: 40px;
    letter-spacing: 1px;
    vertical-align: middle;
 
    text-shadow: 2px 2px 5px rgba(8, 8, 8, 0.156);
   

}     
.title2.wht.c.studio {
      
    text-align: center;
    position: relative;
    margin-left: -10px;
    z-index: 9999;
    font-family: "Sanz-Reg-Expd", sans-serif;
    color: white;
    font-weight: 200;
    font-size: 40px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.492);
    letter-spacing: 1.5px;
    padding-top: 7px;
}

.title3 {
      
    text-align: center;
    position: relative;

    z-index: 9999;
    font-family: "Sanz-Reg-Expd", sans-serif;
    color: black;
    font-weight: 500;
    font-size: 10.22px;
    text-shadow: none;
    text-shadow: .8px .8px 0px rgba(0, 0, 0, 0.265);
    margin-top: 12px;
    letter-spacing: 1.5px;
    margin-right: -14px;
}



.main-body-container2 {
  
}

.footer {
    margin: 0px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: .7rem;
    font-weight: 400;

    line-height: 1.75em;


    position: relative;

    display: block;
}

.blackfriday {
    background: black;
    color: white;
    padding: 0px;
    padding-bottom: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-top: 1px;
}

.c {text-align: center;
}

.blackfriday h1 {
        font-weight: 900;
        color: white; 
        font-size: 24px;
        line-height: 1.1904761905;
        font-weight: 600;
        letter-spacing: .011em;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
        background: radial-gradient( white, black, white, black);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        
        background:linear-gradient(#262626,#eaeaea,#262626);
        -webkit-background-clip: text;
        color:transparent;
        font-family: "Sanz-Reg", sans-serif;
        padding: 10px;
        font-size: 74px;
        line-height: .75em;

  
}




@media (max-width: 390px) {
    .desc-blk, .desc-wht {
        font-size: 10.2px;
    }
}

span.reg {
    font-size: 14px;
    text-align: top;
    position: absolute;
    margin-left: 2px;
    top: -12px;
    font-weight: 300;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

span.reg-nav {
    font-size: 18px;
    text-align: top;
    position: absolute;
    right: -322px;
    top: -4px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif

}


@media (min-width: 800px) {

    .footer {
        margin: 0px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: .7rem;
        font-weight: 400;
        right: 40px;
        padding-top: 20px;
        text-align: right;
        line-height: 1.75em;
        display: block;
    }
    

    .navbar-aam {
        width: 17%;
        top: 270px;
        right: 20px;
        position: absolute;
        z-index: 99;
    }

    .main-title {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   
        color: black;
        text-transform: uppercase;
        letter-spacing: .6px;
        font-size: 10.5px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-top: 20px;
   
        background: white;
        padding-bottom: 10px;
        line-height: 1.4em;
        color: rgb(27, 27, 27);
    
    }


    .title2.wht.c.studio {
      
        text-align: center;
        position: relative;
    
        z-index: 9999;
        font-family: "Sanz-Reg-Expd", sans-serif;
        color: white;
        font-weight: 200;
        font-size: 40px;
        text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.554);
        letter-spacing: 1.5px;
    }

    .main-title a {
        margin: 0 auto;
        font-weight: 600;
        font-size: 14px;
        background: linear-gradient(to left, #020202, #141414, #0f0f0f, #000000, #ffffff, #161616, #2d2d2d, #121212);
        font-family: "Sanz-Reg", sans-serif;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 700;
        background-size: 400% 400%;
        background-clip: text;
        -webkit-background-clip: text;
        color: rgba(0, 0, 0, 0);
        padding: 12px;
        text-transform: none;
        animation: WelcomePageGradient 10s ease infinite;

    }
    
    .best-of img{
        position: absolute;
        width: 18%;
        right: 10px;
        top: 10px;
        z-index: 99
    }

    video.work-sample3 {
        width: 100%;
        margin-left: 0%;
        filter: brightness(1.02);
        border-radius: 8px;
       }

 
    .main-body-container {
        min-width: 1100px;
        height: 100vh;
    }

    source.work-sample2, img.work-sample2 {
        height: 500px;
        width: auto;
        margin-left: -17%;
        top: -82px;
   
    }
    
    .link-container {
   
        display: inline-block;
        position: relative;
        margin: 2.5px auto;
        height: 520px;
        min-width: 360px;
        margin: 2.5px;
        width: 30%;

     
       
        position: relative;
        background: white;
        overflow: hidden;
  

      
    }

    .link-overlay-container {
        position: absolute;
        width: 100%; height: 100%;
        background: linear-gradient(to bottom, rgb(255, 255, 255), transparent, transparent, rgba(255, 255, 255, 0.064), rgba(255, 255, 255, 0.393), white,  white);
        z-index: 99;
     


    }

    .link-overlay-container-black {
        font-family: "Galyon-Reg", sans-serif;
        position: absolute;
        width: 100%; height: 100%;
        background: linear-gradient(to bottom, black, transparent, transparent, transparent, transparent, transparent, rgba(0, 0, 0, 0.641), black,  black);
        z-index: 99;
        background: linear-gradient(to bottom, #271332, transparent, transparent, #21062f87, #21062f, #271332);

    }

    .link-overlay-container-black2 {
        font-family: "Galyon-Reg", sans-serif;
        position: absolute;
        width: 100%; height: 100%;
        background: linear-gradient(to bottom, black, transparent, transparent, transparent, transparent, transparent, rgba(0, 0, 0, 0.641), black,  black);
        z-index: 99;
        background: linear-gradient(to bottom, black,  transparent, transparent, transparent, rgba(0, 0, 0, 0.616), black, black);

    }

    video.work-sample {
        height: 500px;
        width: auto;
        margin-top: -20px;
        position: absolute;
        left: 60%; transform: translate(-50%);
       
    }
    video.work-sample2 {
        height: 400px;
        width: auto;
        margin-top: 0;
        position: absolute;
        left: 70%; transform: translate(-50%);
     
      
    }

    .desc-blk {
        font-size: .8rem;
        position: absolute;
        top: 96%; transform: translate(0, -96%);
        overflow: visible;
    }

   .desc-wht {
        font-size: .8rem;
        position: absolute;
        top: 88%; transform: translate(0, -88%);
        overflow: visible;
    }

    .small-desc {
        font-size: 9px;
    }
  

 

   

}
.fade-in {
	animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@keyframes WelcomePageGradient {
  0%{background-position:0% 47%}
  50%{background-position:100% 54%}
  100%{background-position:0% 47%}
}

.slide-in-right {
	animation: slide-in-right .35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-blurred-top {
	animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

.slide-out-blurred-top {
	animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}

.fade-in {
	animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.slide-out-right {
	animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.fade-out {
	animation: fade-out 1s ease-out both;
}

.slide-in-bottom {
	animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
  @keyframes slide-in-right {
    0% {
      transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
@keyframes slide-in-top {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


  .slide-in-top {
    animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  
  .text-pop-up-top {
    animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
@keyframes text-pop-up-top {
  0% {
    transform: translateY(0);
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    transform: translateY(-50px);
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

.tracking-in-expand {
	animation: tracking-in-expand 1.4s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-14 22:38:20
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes slide-in-blurred-top {
    0% {
      transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
      transform-origin: 50% 0%;
      filter: blur(40px);
      opacity: 0;
    }
    100% {
      transform: translateY(0) scaleY(1) scaleX(1);
      transform-origin: 50% 50%;
      filter: blur(0);
      opacity: 1;
    }
  }
  @keyframes slide-out-blurred-top {
    0% {
      transform: translateY(0) scaleY(1) scaleX(1);
      transform-origin: 50% 0%;
      filter: blur(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-1000px) scaleY(1) scaleX(0.2);
      transform-origin: 50% 0%;
      filter: blur(40px);
      opacity: 0;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in-and-out {
    0% {
      opacity: .1;
    }
    50% {
      opacity: .44;
    }
    100% {
      opacity: .1;
    }
  }
  @keyframes fade-in-and-out2 {
    0% {
      opacity: .44;
    }
    50% {
      opacity: .1;
    }
    100% {
      opacity: .44;
    }
  }
  @keyframes slide-out-right {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(1000px);
      opacity: 0;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  
  
  


  
